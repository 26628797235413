<template>
    <div>
        <section class="ftco-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-4">{{Topic}}</h2>
                        
                        <b-breadcrumb class="pull-left" :items="bread_items"></b-breadcrumb>

                        <label class="text-secondary pull-right">Last Update: {{LastUpdate}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12" v-html="HtmlContent">
                        <!-- {{HtmlContent}} -->
                    </div>
                </div>

                <!-- Gallery -->
                <div v-if="gls.length > 0">
                    <div class="row" >
                        <div class="col-sm-12 pt-4">
                            <h6 class="text-primary">Gallery</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2 col-sm-2" v-for="(gl, idx) in gls" :key="idx">
                            <b-card no-body @click="showImg(idx)">
                                <img :src="gl" class="w-100 img-gal">
                            </b-card>
                        </div>

                        <div class="col-sm-12">
                             <vue-easy-lightbox
                                scrollDisabled
                                escDisabled
                                moveDisabled
                                :visible="gl_visible"
                                :imgs="gls"
                                :index="gl_idx"
                                @hide="handleGalleryHide"
                            ></vue-easy-lightbox>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SiteDataServ from '../services/SiteDataService.vue'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
      VueEasyLightbox
    },
    data(){
        return{
            Topic:"Test",
            HtmlContent:'<b class="text-center">Content not found.</b>',
            LastUpdate:"01/01/2021 00:00",
            Category:"",
            bread_items:[
                {
                    text: 'News',
                    href: '/News'
                }
            ],
            gls:[],
            gl_idx:0,
            gl_visible:false
        }
    },
    methods:{
        GetPageContent(){
            SiteDataServ.NewsGetbyName(this.Topic)
                .then(res => {
                    let _content = res.data;
                    this.HtmlContent = _content.htmldesc;
                    this.LastUpdate = this.DateTimeToText(_content.lastupdate);
                    this.Category = _content.category;
                    // let bcate = {
                    //     text: this.Category,
                    //     href: '/activities/'+this.Category
                    // }
                    // this.bread_items.push(bcate);
                    let bcate = {
                        text: this.Topic,
                        active: true
                    }
                    this.bread_items.push(bcate);
                    this.GetGallery(_content.bid);

                })
                .catch(err => {
                    console.log(err);
                });
        },
        DateTimeToText(inp){
            let result = "";
            let Temp = new Date(inp);
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + (Temp.getDate())).slice(-2);
            let hour = ('0' + Temp.getHours()).slice(-2);
            let minute = ('0' + Temp.getMinutes()).slice(-2);

            result = date+"/"+month+"/"+year+" "+hour+":"+minute;
            return result;
        },
        GetGallery(bid){
            SiteDataServ.GetNewsGallery(bid)
                .then(res => {
                    let _gallery = res.data;
                    this.gls = [];
                    for(let i = 0; i < _gallery.length; i++){
                        let gl = _gallery[i];
                        let imgsrc = this.$bkURL +"imgs/"+gl.pic;
                        this.gls.push(imgsrc);
                    }

                    // this.gl_visible = (this.gls.length > 0 ? true : false);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        handleGalleryHide(){
            this.gl_visible = false;
        },
        showImg(idx){
            this.gl_idx = idx;
            this.gl_visible = true;
        }
    },
    mounted(){
        this.Topic = this.$route.params.name;
        this.GetPageContent();
    }
}
</script>

<style scoped>
.img-gal{
    /* max-width: 200px; */
    height: auto;
    width: 100%;
    cursor: pointer;
}
</style>